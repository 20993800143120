<template>
  <div class="rooms-edit mt-4 ml-8 mr-8">
    <v-container fluid v-if="room">
      <v-form v-model="valid" @submit.prevent="save()">
        <v-row>
          <v-col>
            <h1 class="display-1 mt-2">
              {{ room.name }}
            </h1>
          </v-col>
          <v-col cols="auto">
            <tooltip-button
              icon="history"
              text="History"
              color="default"
              :dark="false"
              @click="$refs.entityLogDialog.open = true"
              classNames="mt"
              position="top"
              size="small"
            />
            <tooltip-button
              icon="event"
              text="Kalender"
              color="default"
              :dark="false"
              classNames="ml-3"
              @click="
                $refs.calendarDialog.room = room;
                $refs.calendarDialog.open = true;
              "
              position="top"
              size="medium"
            />
            <tooltip-button
              icon="save"
              text="Speichern"
              :color="canBeSaved ? 'primary' : 'default'"
              :dark="false"
              @click="save()"
              classNames="ml-3"
              position="top"
              size="medium"
              :disabled="!canBeSaved"
            />
          </v-col>
        </v-row>
        <v-card outlined elevation="0" class="mb-5">
          <v-tabs v-model="tab" grow background-color="grey lighten-3">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">
              <v-icon class="mr-2">meeting_room</v-icon>
              Details
            </v-tab>
            <v-tab href="#images">
              <v-icon class="mr-2">image</v-icon>
              Bilder
            </v-tab>
            <v-tab href="#external">
              <v-icon class="mr-2">settings_system_daydream</v-icon>
              Externe Systeme
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-row>
                <v-col cols="3">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Eigenschaften
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        outlined
                        dense
                        v-model="room.number"
                        label="Nummer"
                        type="text"
                        :maxlength="255"
                        :rules="textRules"
                        placeholder=" "
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        v-model="room.name"
                        label="Name"
                        type="text"
                        :maxlength="255"
                        :rules="textRules"
                        placeholder=" "
                      ></v-text-field>
                      <v-textarea
                        outlined
                        dense
                        v-model="room.description"
                        label="Beschreibung"
                      >
                      </v-textarea>
                      <v-text-field
                        outlined
                        dense
                        v-model="room.size"
                        label="Grösse (m2)"
                        type="number"
                        :max="10000"
                      ></v-text-field>
                      <v-select
                        outlined
                        dense
                        :items="pricingCategories"
                        v-model="room.pricing_category"
                        label="Preis-Kategorie"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                      <v-select
                        outlined
                        dense
                        :items="availableRooms"
                        v-model="room.composition"
                        label="Komposition"
                        item-text="text"
                        item-value="value"
                        multiple
                      ></v-select>
                      <v-switch
                        v-model="room.is_bookable"
                        :label="`Kann gebucht werden`"
                      ></v-switch>
                      <v-switch
                        v-model="isPublished"
                        :label="`Ist publiziert`"
                      ></v-switch>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="5">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Bestuhlung
                    </v-card-title>
                    <v-card-text class="pt-4" style="position: relative">
                      <div style="position:absolute;top:-30px;right:0px;">
                        <tooltip-button
                          icon="add"
                          text="Bestuhlung erfassen"
                          color="default"
                          :dark="false"
                          @click="openCreateDialog()"
                          position="top"
                          size="small"
                          float="right top"
                        />
                      </div>
                      <remote-data-table
                        :url="'backoffice/seatings'"
                        :search-enabled="false"
                        :headers="headers"
                        :append-url-params="'room_id=' + room.id"
                        ref="seatingsDatatable"
                      >
                        <template v-slot:item.type="{ item }">
                          {{
                            configs.seating_types[item.type]
                              ? configs.seating_types[item.type]
                              : "-"
                          }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            x-small
                            class="elevation-0 ml-3 mt-1 mb-1"
                            @click="openEditDialog(item.id)"
                            color="default"
                            fab
                          >
                            <v-icon>
                              edit
                            </v-icon>
                          </v-btn>
                          <v-btn
                            x-small
                            class="elevation-0 ml-1 mt-1 mb-1"
                            @click="openDeleteDialog(item.id, item.name)"
                            color="default"
                            fab
                          >
                            <v-icon>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </remote-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Ausstattung
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        outlined
                        v-model="room.addons"
                        :items="availableAddons"
                        label="Add-Ons"
                        item-text="text"
                        item-value="value"
                        multiple
                        chips
                      ></v-select>
                      <v-select
                        outlined
                        v-model="room.amenities"
                        :items="availableAmenities"
                        label="Austattungen"
                        item-text="text"
                        item-value="value"
                        multiple
                        chips
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="images">
              <v-row>
                <v-col cols="4">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Hauptbild
                    </v-card-title>
                    <v-card-text>
                      <image-upload
                        v-model="room.main_image"
                        :url="`backoffice/rooms/${room.id}/main`"
                        :ratioW="16"
                        :ratioH="9"
                        @changed="refetch()"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Weitere Bilder
                    </v-card-title>
                    <v-card-text>
                      <room-gallery
                        v-model="room"
                        @changed="refetch"
                      ></room-gallery>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="external">
              <v-row>
                <v-col cols="4">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      SIPORT
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        dense
                        outlined
                        v-model="room.siport_profile"
                        label="Profil"
                        type="text"
                        :maxlength="255"
                        placeholder=" "
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      Exchange
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        dense
                        outlined
                        v-model="room.calendar_user"
                        label="Benutzer"
                        type="text"
                        :maxlength="255"
                        placeholder=" "
                        readonly
                        disabled
                      ></v-text-field>
                      <v-text-field
                        dense
                        outlined
                        v-model="room.calendar_password"
                        label="Passwort"
                        type="password"
                        :maxlength="255"
                        placeholder=" "
                        readonly
                        disabled
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card flat class="pa-3">
                    <v-card-title>
                      BP Event
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        dense
                        outlined
                        v-model="room.remote_id"
                        label="ID"
                        type="text"
                        :maxlength="255"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-form>
    </v-container>

    <entity-log-dialog
      v-if="room"
      entity-name="rooms"
      :entity-id="room.id"
      ref="entityLogDialog"
    />
    <calendar-dialog ref="calendarDialog" />
    <seating-dialog
      ref="seatingDialog"
      @saved="$refs.seatingsDatatable.getDataFromApi()"
    />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.seatingsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import TooltipButton from "@/components/forms/TooltipButton.vue";
import EntityLogDialog from "@/components/dialogs/EntityLogDialog.vue";
import CalendarDialog from "@/components/dialogs/CalendarDialog.vue";
import SeatingDialog from "@/components/dialogs/SeatingDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import RemoteDataTable from "@/components/RemoteDataTable";
import RoomGallery from "../../components/rooms/RoomGallery";
import ImageUpload from "../../components/images/ImageUpload";

export default {
  components: {
    ImageUpload,
    RoomGallery,
    TooltipButton,
    EntityLogDialog,
    CalendarDialog,
    SeatingDialog,
    DeleteDialog,
    RemoteDataTable
  },

  data: () => ({
    tab: 0,
    room: null,
    availableAmenities: [],
    availableAddons: [],
    availableRooms: [],
    valid: false,
    dirty: false,
    init: false,
    textRules: [v => !!v || "Muss ausgefüllt sein"],
    selectRules: [v => v.length > 0 || "Muss ausgefüllt sein"],

    headers: [
      { text: "Typ", align: "left", sortable: true, value: "type" },
      { text: "Kapazität", align: "left", sortable: true, value: "capacity" },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ]
  }),

  computed: {
    ...mapState("app", ["configs"]),

    roomId() {
      return this.$route.params.id;
    },

    canBeSaved() {
      return (
        this.valid &&
        this.dirty &&
        this.room &&
        (!this.room.is_bookable ||
          (this.room.is_bookable && this.room.pricing_category !== "99_99"))
      );
    },

    isPublished: {
      get() {
        return !!this.room.published_at;
      },
      set(value) {
        this.room.published_at = value
          ? moment().format("YYYY-MM-DD HH:mm:ss")
          : null;
      }
    },

    pricingCategories() {
      return Object.keys(this.configs.pricing_categories).map(key => ({
        text: this.configs.pricing_categories[key],
        value: key
      }));
    }
  },

  watch: {
    room: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    /**
     * Fetch data from server
     */
    async fetchData() {
      try {
        const responseAmenities = await this.$api.http.get(
          `backoffice/amenities?_per_page=99999`
        );
        this.availableAmenities = responseAmenities.data.data.map(amenity => ({
          text: amenity.name,
          value: amenity.id
        }));

        const responseAddons = await this.$api.http.get(
          `backoffice/addons?_per_page=99999`
        );
        this.availableAddons = responseAddons.data.data.map(addon => ({
          text: addon.name,
          value: addon.id
        }));

        const responseRooms = await this.$api.http.get(
          `backoffice/rooms?_per_page=99999`
        );
        this.availableRooms = responseRooms.data.data
          .filter(room => room.id !== this.roomId)
          .map(room => ({
            text: room.name,
            value: room.id
          }));

        const responseRoom = await this.$api.http.get(
          `backoffice/rooms/${this.roomId}`
        );
        this.room = responseRoom.data;
        this.room.amenities = this.room.amenities.map(amenity => amenity.id);
        this.room.addons = this.room.addons.map(addon => addon.id);
      } catch (error) {
        this.$toast.error("Daten konnten nicht vom Server geladen werden.");
      }
    },

    async refetch() {
      await this.fetchData();
      this.$nextTick(() => {
        this.dirty = false;
      });
    },

    /**
     * Save room.
     */
    save() {
      let payload = { ...this.room };
      this.$api.http
        .put(`backoffice/rooms/${this.room.id}`, payload)
        .then(response => {
          this.$toast.success("Der Raum wurde aktualisiert.");
          this.dirty = false;
        })
        .catch(error => {
          this.$toast.error("Der Raum konnte nicht gespeichert werden.");
        });
    },

    /**
     * Open seating create dialog
     */
    openCreateDialog() {
      this.$refs.seatingDialog.mode = "create";
      this.$refs.seatingDialog.roomId = this.room.id;
      this.$refs.seatingDialog.open = true;
    },

    /**
     * Open seating edit dialog
     */
    openEditDialog(id) {
      this.$refs.seatingDialog.mode = "edit";
      this.$refs.seatingDialog.roomId = this.room.id;
      this.$refs.seatingDialog.seatingId = id;
      this.$refs.seatingDialog.open = true;
    },

    /**
     * Open seating delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "seatings";
      this.$refs.deleteDialog.open = true;
    }
  }
};
</script>

<style lang="scss">
.rooms-edit {
  .v-tabs-items {
    width: 100%;
    height: 100%;
  }
}
</style>
