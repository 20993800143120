<template>
  <div class="room-gallery">
    <draggable
      class="room-gallery-container"
      v-model="gallery"
      group="group"
      @end="sortGallery()"
      handle=".image-handle"
    >
      <div
        class="room-gallery-item"
        v-for="(image, index) in gallery"
        :key="index"
      >
        <v-card elevation="0" flat outlined>
          <v-card-text>
            <image-upload
              v-model="gallery[index].url"
              :ratio-h="9"
              :ratio-w="16"
              :url="`backoffice/rooms/${value.id}/gallery/${index}`"
              @changed="fetchGallery"
            />
          </v-card-text>
        </v-card>
      </div>
      <v-card elevation="0" flat class="room-gallery-item">
        <v-card-text class="text-center">
          <p>Neues Bild hinzufügen</p>
          <v-btn fab @click="addImage()" elevation="0">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </draggable>
  </div>
</template>

<script>
import ImageUpload from "../images/ImageUpload";
import draggable from "vuedraggable";

export default {
  name: "RoomGallery",
  components: { ImageUpload, draggable },
  props: ["value"],

  data: () => ({
    gallery: [],
    drag: false
  }),

  mounted() {
    this.fetchGallery();
  },

  methods: {
    fetchGallery() {
      this.$api.http
        .get(`backoffice/rooms/${this.value.id}/gallery`)
        .then(response => {
          this.gallery = response.data;
        })
        .catch(() => {
          this.$toast.error("Gallery konnte nicht geladen werden.");
        });
    },

    addImage() {
      this.gallery.push({ id: null, original: null, url: null });
    },

    sortGallery() {
      const payload = { gallery: this.gallery.map(i => i.id) };
      this.$api.http
        .put(`backoffice/rooms/${this.value.id}`, payload)
        .then(response => {
          this.$toast.success("Sortierung wurde gespeichert");
        })
        .catch(() => {
          this.$toast.error("Gallery konnte nicht sortiert werden.");
        });
    }
  }
};
</script>

<style scoped lang="scss">
.room-gallery {
  position: relative;
  margin-top: -20px;
  padding-top: 20px;

  .room-gallery-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;

    .room-gallery-item {
    }
  }
}
</style>
