<template>
  <v-dialog v-model="open" scrollable width="600">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Blocker erfassen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <date-picker v-model="date" label="Datum" />
        <v-row>
          <v-col>
            <time-picker v-model="startsAt" label="Von" />
          </v-col>
          <v-col>
            <time-picker v-model="endsAt" label="Bis" />
          </v-col>
        </v-row>
        <v-text-field
          outlined
          dense
          v-model="subject"
          label="Betreff"
        />
        <v-text-field
          outlined
          dense
          v-model="reference"
          label="Referenz"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="create()" :disabled="!valid">
          Erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/forms/DatePicker";
import TimePicker from "@/components/forms/TimePicker";
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  props: {
    room: {
      type: Object,
      required: true
    }
  },

  components: {
    TimePicker,
    DatePicker
  },

  data: () => ({
    open: false,
    date: moment(),
    startsAt: moment("08:00", "HH:mm"),
    endsAt: moment("19:00", "HH:mm"),
    subject: null,
    reference: null
  }),

  computed: {
    valid() {
      return this.date && this.startsAt && this.endsAt && this.subject;
    }
  },

  methods: {
    /**
     * Delete entity.
     */
    async create() {
      try {
        const payload = {
          starts_at:
            this.date.format("YYYY-MM-DD") +
            " " +
            this.startsAt.format("HH:mm:ss"),
          ends_at:
            this.date.format("YYYY-MM-DD") +
            " " +
            this.endsAt.format("HH:mm:ss"),
          subject: this.subject,
          reference: this.reference
        };
        await this.$api.http.post(
          `backoffice/room-bookings/${this.room.id}/blockers`,
          payload
        );
        this.$emit("created");
        this.open = false;
      } catch (error) {
        this.$toast.error("Blocker konnte nicht erstellt werden.");
      }
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>
