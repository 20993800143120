<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-form v-model="valid" v-if="seating" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="mode === 'create'">Bestuhlung erfassen</span>
          <span v-if="mode === 'edit'">Bestuhlung bearbeiten</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-select
                outlined
                dense
                v-model="seating.type"
                :items="types"
                item-text="text"
                item-value="value"
                label="Typ"
                :rules="textRules"
              ></v-select>
              <v-text-field
                outlined
                dense
                v-model="seating.capacity"
                label="Kapazität"
                type="number"
                :maxlength="255"
                :rules="numericRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    mode: "create",
    valid: false,

    seatingId: null,
    seating: null,
    roomId: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"],
    numericRules: [v => parseInt(v) > 0 || "Muss eine Nummer > 0 sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        if (this.mode === "create") {
          this.resetForm();
        } else if (this.mode === "edit") {
          this.fetchSeating();
        }
      }
    }
  },

  computed: {
    ...mapState("app", ["configs"]),

    types() {
      return Object.keys(this.configs.seating_types).map(type => ({
        text: this.configs.seating_types[type],
        value: type
      }));
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.seating = {
        id: null,
        type: null,
        capacity: null
      };
    },

    /**
     * Fetch seating from server
     */
    fetchSeating() {
      this.$api.http
        .get(`backoffice/seatings/${this.seatingId}`)
        .then(response => {
          this.seating = response.data;
        });
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save seating.
     */
    save() {
      if (this.mode === "create") {
        this.$api.http
          .post(`backoffice/seatings`, {
            ...this.seating,
            ...{ room_id: this.roomId }
          })
          .then(() => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      } else if (this.mode === "edit") {
        this.$api.http
          .put(`backoffice/seatings/${this.seatingId}`, {
            ...this.seating,
            ...{ room_id: this.roomId }
          })
          .then(() => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      }
    }
  }
};
</script>
