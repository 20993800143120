<template>
  <v-dialog v-model="open" scrollable width="1200">
    <v-card v-if="room">
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Kalender {{ room.name }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="5">
            <v-btn icon x-large class="default" @click="$refs.calendar.prev()">
              <v-icon>navigate_before</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-center pt-5" cols="2">
            <date-picker
              v-model="value"
              :outlined="true"
              :dense="true"
            ></date-picker>
          </v-col>
          <v-col cols="5" class="text-right">
            <v-btn icon x-large class="default" @click="$refs.calendar.next()">
              <v-icon>navigate_next</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-sheet class="mb-2">
          <v-calendar
            ref="calendar"
            v-model="calendarDate"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            type="week"
            first-interval="7"
            interval-count="16"
            :events="events"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
            @click:event="deleteBlocker"
          >
            <template v-slot:event="{ event, eventParsed }">
              <div class="ma-2" style="white-space: normal;">
                <div class="font-weight-bold">{{ event.type }}</div>
                <div>{{ event.title }}</div>
                <div class="mt-2">
                  {{ eventParsed.start.time }} bis {{ eventParsed.end.time }}
                </div>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="primary"
          class="elevation-0"
          @click="$refs.blockerDialog.open = true"
        >
          Blocker erfassen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
    <blocker-dialog ref="blockerDialog" :room="room" @created="refetch()" />
    <delete-dialog ref="deleteDialog" :room="room" @deleted="refetch()" />
  </v-dialog>
</template>

<script>
import moment from "moment";
import BlockerDialog from "@/components/dialogs/BlockerDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import DatePicker from "../forms/DatePicker";

export default {
  components: {
    DatePicker,
    BlockerDialog,
    DeleteDialog
  },

  data: () => ({
    open: false,
    room: null,
    events: [],
    value: moment()
  }),

  computed: {
    calendarDate: {
      get() {
        return this.value.format("YYYY-MM-DD");
      },
      set(value) {
        this.value = moment(value);
      }
    }
  },

  methods: {
    /**
     * Fetch events from server.
     */
    async getEvents() {
      if (this.room) {
        const start = this.value.startOf("week").format("YYYY-MM-DD");
        const end = this.value.endOf("week").format("YYYY-MM-DD");
        const response = await this.$api.http.get(
          `backoffice/room-bookings/${this.room.id}?start=${start}&end=${end}`
        );
        this.events = [];
        response.data.forEach(e => {
          this.events.push({
            type: e.type,
            title: e.title,
            start: new Date(e.starts_at),
            end: new Date(e.ends_at),
            status: e.status,
            timed: true,
            id: e.id,
            isDeleteable: e.status === "blocker"
          });
        });
      }
    },

    /**
     * Refetch.
     */
    refetch() {
      this.events = [];
      this.getEvents();
    },

    /**
     * Get event color based on status.
     */
    getEventColor(event) {
      if (event.status === "confirmed") {
        return "success";
      } else if (event.status === "pending") {
        return "warning";
      } else if (event.status === "blocker") {
        return "info";
      } else if (event.status === "event") {
        return "purple";
      } else {
        return "danger";
      }
    },

    deleteBlocker({ nativeEvent, event }) {
      if (event.isDeleteable) {
        this.$refs.deleteDialog.entityId = event.id;
        this.$refs.deleteDialog.entityName = `#${event.id} ${event.name}`;
        this.$refs.deleteDialog.entityUrl = "room-bookings/blockers";
        this.$refs.deleteDialog.open = true;
      }
    },

    /**
     * close.
     */
    close() {
      this.open = false;
    }
  }
};
</script>

<style></style>
